import { dew as _toArrayDew } from "./to-array";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  var bind = window.addEventListener ? "addEventListener" : "attachEvent",
      unbind = window.removeEventListener ? "removeEventListener" : "detachEvent",
      prefix = bind !== "addEventListener" ? "on" : "",
      toArray = _toArrayDew();
  /**
   * Bind `el` event `type` to `fn`.
   *
   * @param {Element} el, NodeList, HTMLCollection or Array
   * @param {String} type
   * @param {Function} fn
   * @param {Boolean} capture
   * @api public
   */


  exports.bind = function (el, type, fn, capture) {
    el = toArray(el);

    for (var i = 0, il = el.length; i < il; i++) {
      el[i][bind](prefix + type, fn, capture || false);
    }
  };
  /**
   * Unbind `el` event `type`'s callback `fn`.
   *
   * @param {Element} el, NodeList, HTMLCollection or Array
   * @param {String} type
   * @param {Function} fn
   * @param {Boolean} capture
   * @api public
   */


  exports.unbind = function (el, type, fn, capture) {
    el = toArray(el);

    for (var i = 0, il = el.length; i < il; i++) {
      el[i][unbind](prefix + type, fn, capture || false);
    }
  };
  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * `wait` milliseconds. If `immediate` is true, trigger the function on the
   * leading edge, instead of the trailing.
   *
   * @param {Function} fn
   * @param {Integer} wait
   * @param {Boolean} immediate
   * @api public
   */


  exports.debounce = function (fn, wait, immediate) {
    var timeout;
    return wait ? function () {
      var context = this || _global,
          args = arguments;

      var later = function () {
        timeout = null;
        if (!immediate) fn.apply(context, args);
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) fn.apply(context, args);
    } : fn;
  };

  return exports;
}