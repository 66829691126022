var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * A cross-browser implementation of getAttribute.
   * Source found here: http://stackoverflow.com/a/3755343/361337 written by Vivin Paliath
   *
   * Return the value for `attr` at `element`.
   *
   * @param {Element} el
   * @param {String} attr
   * @api public
   */
  exports = function (el, attr) {
    var result = el.getAttribute && el.getAttribute(attr) || null;

    if (!result) {
      var attrs = el.attributes;
      var length = attrs.length;

      for (var i = 0; i < length; i++) {
        if (attrs[i] !== undefined) {
          if (attrs[i].nodeName === attr) {
            result = attrs[i].nodeValue;
          }
        }
      }
    }

    return result;
  };

  return exports;
}